<!--
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-06-20 19:39:27
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-10-18 17:49:38
 * @FilePath: /frontend/src/views/pages/articleReview/Detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <b-button
      variant="primary"
      class="mr-2"
      @click="expor()"
      size="sm"
      :disabled="exportDisabled || disabled"
      >{{ exportText }}
      <!-- <b-icon
        v-if="disabled"
        class="counterclockwise"
        icon="arrow-counterclockwise"
        animation="spin-reverse"
        font-scale="1.2"
      ></b-icon>
      <b-icon
        v-else
        class="counterclockwise"
        :icon="exportIcon ? exportIcon : 'arrow-counterclockwise'"
        font-scale="1.2"
      >
      </b-icon> -->
    </b-button>
  </div>
</template>
  
  <script>
import JwtService from '@/common/services/jwt';
export default {
  name: 'PrintPDF',
  data() {
    return {
      disabled: false
    };
  },
  props: {
    exportType: String,
    exportText: String,
    exportUrl: String,
    exportDisabled: Boolean,
    fileName: String,
    saveData: Array,
    exportIcon: String,
    totalItems: Number
  },
  components: {},
  methods: {
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },
    expor: function () {
      // this.disabled = true;
      let url, childWin;
      url = this.exportUrl + '&token=' + JwtService.getToken();
      // eslint-disable-next-line no-useless-escape
      url = url.replace(/\n|\r/g, '').replace(/\ +/g, '');
      console.log(url);
      childWin = window.open(
        url,
        this.fileName,
        'height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
      );
      childWin.onload = function () {
        // childWin.print();
        // this.disabled = false;
      };
    }
  },

  computed: {},
  mounted() {
    // console.log(JwtService.getToken());
  }
};
</script>
  <style scoped></style>
  