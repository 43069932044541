<template>
  <div style="display: inline-block">
    <b-button
      variant="danger"
      class="mr-2 confirm-delivery"
      :disabled="disabled"
      @click="onShowModal()"
      >Reject
      <i class="fas fa-ban"></i>
    </b-button>
    <!-- @hide="modelHide" -->
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Rejection Confirm"
      id="rejection_confirm"
      ref="modal"
    >
      <b-overlay :show="modalloading">
        <p v-if="storeData.state > 0">
          {{ storeData.state }} articles will be rejected. Please confirm and
          enter the reject reason.
        </p>
        <p v-else>
          Are you sure to set the review status to "Rejected"? Please enter the
          reject reason and comment.
        </p>
        <b-row>
          <b-col md="12" class="my-1">
            <b-form-group
              label="Reject Reason"
              label-for="reject"
              class="search-flex reject required"
              label-cols-sm="4"
              label-cols-lg="3"
              :class="{
                form_error:
                  submitError &&
                  (storeData.reject_reason == null ||
                    storeData.reject_reason == '')
              }"
            >
              <SelectLoading
                v-if="options.reject == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="storeData.reject_reason"
                :placeholder="$t('page.please_choose')"
                :options="options.reject"
                :reduce="options => options.value"
                :selectable="options => !options.selectable"
                label="label"
              >
                <!-- @input="oninput($event)" -->
              </sv-select>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label="Rejection Comment"
              label-for="comment"
              class="search-flex comment required"
              label-cols-sm="4"
              label-cols-lg="3"
              :class="{
                form_error:
                  submitError &&
                  (storeData.reject_note == null || storeData.reject_note == '')
              }"
            >
              <b-form-textarea
                v-model="storeData.reject_note"
                id="comment"
                rows="8"
                :placeholder="$t('page.please_choose_textarea')"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1" v-if="0">
            <b-form-group
              label="Uploader"
              label-for="uploader"
              class="search-flex"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <van-uploader
                v-model="uploaderList"
                :preview-size="100"
                multiple
                ref="vanUploader"
              >
                <template #preview-cover="{ file }">
                  <div class="preview-cover van-ellipsis">
                    {{ file.name }}
                  </div>
                </template>
              </van-uploader>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SelectLoading from '@/components/Loading/Index.vue';
// import axios from 'axios';
export default {
  name: 'ArticleReviewReject',

  data() {
    return {
      submitDisabled: false,
      disabled: false,
      modalloading: false,
      showModal: false,
      reject: null,
      comment: null,
      submitError: false,
      // uploaderList: [],
      options: {
        reject: [
          {
            label: 'Wrong Aritcle',
            value: 'Wrong Aritcle'
          },
          {
            label: 'Damaged Article',
            value: 'Damaged Article'
          },
          {
            label: 'Others',
            value: 'Others'
          }
        ]
      }
    };
  },
  props: ['storeData', 'initDataFn', 'articleInformation'],
  components: { SelectLoading },
  methods: {
    onShowModal() {
      this.showModal = true;
      this.submitDisabled = false;
      this.storeData.reject_note = null;
      this.storeData.reject_reason = null;
      this.storeData.state = null;
      this.submitError = false;
    },
    submit() {
      // console.log(this.uploaderList);
      // var formData = new FormData();
      // formData.append('file1', this.uploaderList[0].file);
      // console.log(formData.get('file1'));
      // console.log(formData.get('file2'));
      // apiBus.articleReview
      //   .articleReviewStore2({
      //     formData
      //   })
      //   .then(data => {
      //     console.log(data);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      if (
        this.storeData.reject_note == null ||
        this.storeData.reject_note == '' ||
        this.storeData.reject_reason == null ||
        this.storeData.reject_reason == ''
      ) {
        this.submitError = true;
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      let data = {
        article_review_id: this.storeData.article_review_id,
        storage_qty: this.storeData.storage_qty,
        reject_reason: this.storeData.reject_reason,
        reject_note: this.storeData.reject_note,
        ean_number: this.articleInformation.ean_number.model,
        origin_country: this.articleInformation.origin_country.model
      };
      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.articleReview
        .articleReviewStore(data)
        .then(data => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.submitError = false;
          this.initDataFn();
        })
        .catch(error => {
          console.log(error);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    // oninput(e) {
    //   // this.storeData.reject_note = e;
    // },
    init() {
      // document.addEventListener('keyup', e => {
      //   if (
      //     this.$refs != undefined &&
      //     this.$refs.vanUploader != undefined &&
      //     this.$refs.vanUploader.imagePreview != undefined
      //   ) {
      //     if (e.keyCode == 39) {
      //       this.$refs.vanUploader.imagePreview.$refs.swipe.next();
      //     } else if (e.keyCode == 37) {
      //       this.$refs.vanUploader.imagePreview.$refs.swipe.prev();
      //     }
      //   }
      // });
    }
  },

  computed: {},
  watch: {
    'storeData.state'(o) {
      if (o > 0) {
        this.showModal = true;
        this.submitDisabled = false;
        this.storeData.reject_note = null;
        this.storeData.reject_reason = null;
        this.submitError = false;
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
#void_target .modal-body {
  padding-bottom: 150px;
}
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
</style>
