<template>
  <div class="mt--2">
    <KTPortlet>
      <template v-slot:title>
        <h3
          class="kt-portlet__head-title"
          style="width: 100%"
          v-if="informationData.information"
        >
          {{ informationData.information.purchase_id.model }} /
          {{ informationData.article_information.l.brand_article_number.model }}
          /
          {{ informationData.article_information.l.product_name_en.model }}
          /
          {{ informationData.information.order_id.model }}
        </h3>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading">
          <b-tabs content-class="mt-3 mb-7" lazy v-model="tabIndex">
            <b-tab title="Information">
              <Information
                :initData="informationData"
                :formError.sync="form_error"
              ></Information>
            </b-tab>
          </b-tabs>
          <div class="console" v-if="ifConsole">
            <b-overlay :show="loading">
              <b-button
                v-if="0"
                variant="primary"
                class="mr-2"
                :disabled="saveDisabled"
                @click="save()"
                >Save
                <i class="far fa-save"></i>
              </b-button>
              <!-- v-if="
                  informationData.information.status.display_text == 'Received'
                " -->
              <Store
                v-if="0"
                :storeData="storeData"
                :initDataFn="initDataFn"
                :formError.sync="form_error"
                :maxQty="informationData.reviewer_status.purchase_qty.model"
                :articleInformation="informationData.article_information"
              ></Store>
              <!-- v-if="
                  informationData.information.status.display_text == 'Received'
                " -->
              <Reject
                v-if="0"
                :articleInformation="informationData.article_information"
                :storeData="storeData"
                :initDataFn="initDataFn"
              ></Reject>

              <PrintPDF
                v-if="0"
                v-b-popover.hover.topleft=""
                :exportText="'Print Stock in Label'"
                :exportUrl="exportPDFUrl"
                :exportDisabled="exportPDFDisabled"
                fileName="Stock in Label"
              ></PrintPDF>

              <router-link
                :to="{
                  name: 'article-review-list'
                }"
                class="btn btn-secondary"
                >Back to List
                <b-icon icon="reply-fill" aria-hidden="true"></b-icon
              ></router-link>
            </b-overlay>
          </div>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import Information from '@/views/content/Detail/ArticleReview/Information/Index.vue';
import apiBus from '@/common/apiBus/index';
import Store from '@/views/content/Detail/ArticleReview/Information/Store.vue';
import Reject from '@/views/content/Detail/ArticleReview/Information/Reject.vue';

import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import DeliveryReceived from '@/views/content/DeliveryReceived/Index.vue';
// import Link from '@/views/content/NewAdd/Information/Link';
import PrintPDF from '@/components/Print/PDF.vue';
export default {
  name: 'ArticleReviewDetail',
  components: { KTPortlet, Information, Store, Reject, PrintPDF },
  data() {
    return {
      exportPDFUrl: '',
      exportPDFDisabled: false,
      tabIndex: 0,
      loading: false,
      informationData: {},
      article_review_id: null,
      id: null,
      purchase_id: null,
      ifConsole: false,
      saveDisabled: false,
      form_error: false,
      storeData: {
        article_review_id: null,
        storage_qty: 0,
        reject_reason: '',
        reject_note: '',
        state: null
      },
      items: [],
      fields: [
        {
          key: 'purchase_id',
          label: 'Purchase ID'
        },
        {
          key: 'order_id',
          label: 'Order ID'
        },
        {
          key: 'image',
          label: 'Image',
          variant: 'w-110'
        },
        {
          key: 'article_information',
          label: 'Article Information',
          variant: 'mx-300'
        },
        {
          key: 'qty',
          label: 'Delivery Quantity'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      save_data: [],
      options: {
        origin_country: []
      }
    };
  },

  methods: {
    initDataFn() {
      this.loading = true;
      this.voidDisabled = false;
      return apiBus.articleReview
        .articleReviewDetail({
          article_review_id: this.article_review_id
        })
        .then((data) => {
          this.loading = false;
          this.ifConsole ? '' : (this.ifConsole = true);
          this.informationData = this.handleData(data.data.data);
          // this.items = this.initTable(data.data.data.purchase_list);
          switch (this.informationData.information.status.display_text) {
            case 'Received':
              this.informationData.information.reserve_no.show = true;
              break;
            case 'Stored':
              this.informationData.reviewer_status.reject_note.show = true;
              this.informationData.reviewer_status.reject_reason.show = true;
              this.informationData.information.reserve_no.show = true;
              break;
            case 'Rejected':
              this.informationData.reviewer_status.reject_note.show = true;
              this.informationData.reviewer_status.reject_note.disabled = false;
              this.informationData.reviewer_status.reject_reason.show = true;
              this.informationData.reviewer_status.reject_reason.disabled = false;
              this.informationData.information.reserve_no.disabled = false;
              break;
          }
          return this.informationData;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    initTime(data) {
      var time = data;
      if (time.indexOf('.') > 0) {
        time = `${time.split('.')[2]}-${time.split('.')[1]}-${
          time.split('.')[0]
        }`;
      }
      return time;
    },
    initTable(data) {
      var tableData = [];
      data.forEach((item) => {
        let {
          id,
          brand,
          brand_article_number,
          product_name,
          product_name_en,
          color,
          color_name,
          size,
          pos_article_no,
          image_url,
          qty,
          purchase_id,
          order_id
        } = item;
        tableData.push({
          purchase_id: purchase_id,
          order_id: order_id,
          image: image_url,
          qty: qty,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size,
            pos_article_no: pos_article_no
          },
          id: id,
          action: 'WarehouseEntranceDetail'
        });
      });
      return tableData;
    },
    handleData(data) {
      var handle_data = {};
      let {
        id,
        purchase_id,
        status,
        order_id,
        supplier_name,
        reception_date,
        product_info,
        purchase_qty,
        stored_qty,
        rejected_qty,
        review_note,
        reviewer,
        last_modification_at,
        last_modification_by,
        reject_note,
        warehouse,
        reject_reason,
        reserve_no,
        channel
      } = data;
      let {
        image_url,
        brand,
        brand_article_number,
        product_name,
        product_name_en,
        color_name,
        color,
        size,
        ean_number,
        pos_article_no,
        origin_country
      } = product_info;

      this.id = id;
      handle_data = {
        information: {
          purchase_id: {
            type: 'input',
            text: 'Purchase ID',
            model: purchase_id,
            disabled: true
          },
          status: {
            type: 'text',
            text: 'Status',
            display_text: status,
            class: 'status-text status',
            text_class: 'text color_' + status.replace(' ', '_')
          },
          order_id: {
            type: 'input',
            text: 'Order ID',
            model: order_id,
            disabled: true
          },
          reserve_no: {
            type: 'input',
            text: 'Reserve No.',
            model: reserve_no,
            disabled: true,
            show: true
          },
          reception_date: {
            type: 'date',
            text: 'Reception Date',
            model: this.initTime(reception_date),
            disabled: true
          },
          supplier_name: {
            type: 'input',
            text: 'Supplier Name',
            model: supplier_name,
            disabled: true
          },
          warehouse: {
            type: 'input',
            text: 'Warehouse',
            model: warehouse,
            disabled: true
          },
          channel: {
            type: 'input',
            text: 'Channel',
            model: channel,
            disabled: true
          }
        },
        article_information: {
          l: {
            brand: {
              type: 'input',
              text: 'Brand',
              model: brand,
              disabled: true
            },
            brand_article_number: {
              type: 'input',
              text: 'Brand Article Number',
              model: brand_article_number,
              disabled: true
            },
            product_name: {
              type: 'input',
              text: 'Article Name CN',
              model: product_name,
              disabled: true
            },
            product_name_en: {
              type: 'input',
              text: 'Article Name EN',
              model: product_name_en,
              disabled: true
            }
          },
          r: {
            image_url: {
              type: 'img',
              text: '',
              url: image_url
            }
          },
          color: {
            type: 'input',
            text: 'Color',
            model: color_name,
            disabled: true,
            icon_color: color
          },
          size: {
            type: 'input',
            text: 'Size',
            model: size,
            disabled: true
          },
          pos_article_no: {
            type: 'input',
            text: 'POS Article No.',
            model: pos_article_no,
            disabled: true
          },
          ean_number: {
            type: 'input',
            text: 'EAN Number',
            required: true,
            model: ean_number,
            disabled: true
          },
          origin_country: {
            type: 'select',
            text: 'Country of Origin',
            required: true,
            model: origin_country,
            disabled: true,
            options: this.options.origin_country || []
          }
        },
        reviewer_status: {
          purchase_qty: {
            type: 'input',
            text: 'Delivery Quantity',
            model: purchase_qty || 0,
            disabled: true
          },
          stored_qty: {
            type: 'input',
            text: 'Storage Quantity',
            model: stored_qty,
            disabled: true
          },
          rejected_qty: {
            type: 'input',
            text: 'Rejection Quantity',
            model: rejected_qty,
            disabled: true
          },
          reject_reason: {
            type: 'select',
            text: 'Reject Reason',
            model: reject_reason,
            options: [
              {
                label: 'Wrong Aritcle',
                value: 'Wrong Aritcle'
              },
              {
                label: 'Damaged Article',
                value: 'Damaged Article'
              },
              {
                label: 'Others',
                value: 'Others'
              }
            ],
            disabled: true,
            show: false
          },
          review_note: {
            type: 'textarea',
            text: 'Review Note',
            model: review_note,
            rows: 8,
            disabled: false,
            placeholder: ''
          },
          reject_note: {
            type: 'textarea',
            text: 'Rejection Comment',
            model: reject_note,
            rows: 8,
            disabled: true,
            placeholder: '',
            show: false
          }
        },
        purchaser: {
          reviewer: {
            type: 'input',
            text: 'Reviewer',
            model: reviewer,
            disabled: true
          },
          station: {},
          last_modification_at: {
            type: 'input',
            text: 'Last Modification at',
            model: last_modification_at,
            disabled: true
          },
          last_modification_by: {
            type: 'input',
            text: 'Last Modification by',
            model: last_modification_by,
            disabled: true
          }
        }
      };

      return handle_data;
      // var handle_data = {};
    },
    save() {
      this.saveDisabled = true;
      this.loading = true;

      let data = {
        ean_number: this.informationData.article_information.ean_number.model,
        origin_country:
          this.informationData.article_information.origin_country.model,
        review_note: this.informationData.reviewer_status.review_note.model,
        article_review_id: this.article_review_id
      };
      switch (this.informationData.information.status.display_text) {
        case 'Rejected':
          data.reject_note =
            this.informationData.reviewer_status.reject_note.model;
          data.reject_reason =
            this.informationData.reviewer_status.reject_reason.model;
          break;
      }
      apiBus.articleReview
        .articleReviewSave(data)
        .then((data) => {
          console.log(data);
          this.saveDisabled = false;
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          this.saveDisabled = false;
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {
      let { article_review_id } = this.$route.params;
      console.log(this.$route.params);
      this.article_review_id = article_review_id;
      this.storeData.article_review_id = article_review_id;
      this.exportPDFUrl =
        '/rest/api/logistic/print-stock-in-label?article_review_id=' +
        this.article_review_id;
      apiBus.purchase
        .getCountryList()
        .then((data) => {
          this.options.origin_country = data.data.data.country_list;
        })
        .catch((error) => {
          console.log(error);
        });
      this.initDataFn();
    }
  },

  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    'options.origin_country'(n) {
      if (
        this.informationData.article_information &&
        this.informationData.article_information.origin_country.options
          .length == 0
      ) {
        this.informationData.article_information.origin_country.options = n;
      }
    }
  }
};
</script>
<style scoped></style>
