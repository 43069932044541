var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},[_c('b-button',{staticClass:"mr-2 confirm-delivery",attrs:{"variant":"danger","disabled":_vm.disabled},on:{"click":function($event){return _vm.onShowModal()}}},[_vm._v("Reject "),_c('i',{staticClass:"fas fa-ban"})]),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","scrollable":"","title":"Rejection Confirm","id":"rejection_confirm"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return close()}}},[_vm._v(" Cancel ")])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-overlay',{attrs:{"show":_vm.modalloading}},[(_vm.storeData.state > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.storeData.state)+" articles will be rejected. Please confirm and enter the reject reason. ")]):_c('p',[_vm._v(" Are you sure to set the review status to \"Rejected\"? Please enter the reject reason and comment. ")]),_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"search-flex reject required",class:{
              form_error:
                _vm.submitError &&
                (_vm.storeData.reject_reason == null ||
                  _vm.storeData.reject_reason == '')
            },attrs:{"label":"Reject Reason","label-for":"reject","label-cols-sm":"4","label-cols-lg":"3"}},[(_vm.options.reject == 0)?_c('SelectLoading',{attrs:{"size":0.7}}):_vm._e(),_c('sv-select',{attrs:{"placeholder":_vm.$t('page.please_choose'),"options":_vm.options.reject,"reduce":function (options) { return options.value; },"selectable":function (options) { return !options.selectable; },"label":"label"},model:{value:(_vm.storeData.reject_reason),callback:function ($$v) {_vm.$set(_vm.storeData, "reject_reason", $$v)},expression:"storeData.reject_reason"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"search-flex comment required",class:{
              form_error:
                _vm.submitError &&
                (_vm.storeData.reject_note == null || _vm.storeData.reject_note == '')
            },attrs:{"label":"Rejection Comment","label-for":"comment","label-cols-sm":"4","label-cols-lg":"3"}},[_c('b-form-textarea',{attrs:{"id":"comment","rows":"8","placeholder":_vm.$t('page.please_choose_textarea')},model:{value:(_vm.storeData.reject_note),callback:function ($$v) {_vm.$set(_vm.storeData, "reject_note", $$v)},expression:"storeData.reject_note"}})],1)],1),(0)?_c('b-col',{staticClass:"my-1",attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"search-flex",attrs:{"label":"Uploader","label-for":"uploader","label-cols-sm":"4","label-cols-lg":"3"}},[_c('van-uploader',{ref:"vanUploader",attrs:{"preview-size":100,"multiple":""},scopedSlots:_vm._u([{key:"preview-cover",fn:function(ref){
            var file = ref.file;
return [_c('div',{staticClass:"preview-cover van-ellipsis"},[_vm._v(" "+_vm._s(file.name)+" ")])]}}],null,false,2546201400),model:{value:(_vm.uploaderList),callback:function ($$v) {_vm.uploaderList=$$v},expression:"uploaderList"}})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }